@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Prompt", sans-serif !important;
}

.box-dashboard {
  border-radius: 0.75rem;
  background-color: #eff1f9;
}
.mp-set {
  margin: 2% 3%;
  padding: 2% 2%;
}
@media only screen and (max-width: 768px) {
  .set-w-100 {
    width: 100%;
  }
  .map-container {
    height: 100vh !important;
    width: 100%;
    height: 810px;
  }
  .text-1 {
    line-height: 1.25rem;
    font-size: 0.855rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
  }
  .text-2 {
    line-height: 1.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
  }
  .pt-set {
    padding-top: 9%;
  }
}
@media only screen and (min-width: 769px) {
  .map-container {
    height: 100vh;
  }
  .pt-set {
    padding-top: 35%;
  }
  .b-bg {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(25px);
  }
  .text-1 {
    line-height: 1.25rem;
    font-size: 0.895rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
  }
  .text-2 {
    line-height: 1.5rem;
    font-size: 1.3rem;
    letter-spacing: 0.05rem;
    font-weight: 500;
    color: #000000;
  }
}

.fab-icon {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.root {
  width: 100% !important;
  height: 100% !important;
}

body {
  overflow: hidden;
  height: 100vh;
}

#root {
  height: 100vh;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
  background-color: rgba(0, 0, 0,0.1) !important;
  backdrop-filter: blur(5px) !important;
}

.marker {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  cursor: pointer;
}